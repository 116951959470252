import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import TodayIcon from "@mui/icons-material/Today";
import { Block } from "@ethersproject/abstract-provider";

export interface BlockInfoProps {
  block: Block | undefined;
}

const BlockInfo = ({ block }: BlockInfoProps) => {
  if (block) {
    const date: Date = new Date(block.timestamp * 1000);

    return (
      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <TodayIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Transaction Date" secondary={date.toLocaleString()} />
        </ListItem>
      </List>
    );
  }

  return null;
};

export default BlockInfo;
