const NetWarning = () => {
  return (
    <>
      <p className="mainnet-warning">
        This application is connected to <strong>{process.env.REACT_APP_CHAIN}</strong>
      </p>
    </>
  );
};

export default NetWarning;
