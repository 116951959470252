/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Button, Box } from "@mui/material";

interface ShareLinkButtonProps {
  link: string;
}

const ShareLinkButton = ({ link }: ShareLinkButtonProps) => {
  const [isClicked, setIsClicked] = useState<boolean>(false);

  useEffect(() => {
    if (!isClicked) return;
    navigator.clipboard.writeText(link);

    const timeout = setTimeout(() => {
      setIsClicked(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [isClicked]);

  return (
    <Box
      component="span"
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <Button variant="contained" sx={{ mt: 3, mb: 2 }} onClick={() => setIsClicked(true)}>
        {!isClicked ? "Get Share Link" : "Link copied to clipboard!"}
      </Button>
    </Box>
  );
};

export default ShareLinkButton;
