import { ethers } from "ethers";

const ERC721_ABI = new ethers.utils.Interface([
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
]);

const RANDOM_ABI = new ethers.utils.Interface([
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256[]",
        name: "randomWords",
        type: "uint256[]",
      },
    ],
    name: "ReturnedRandomness",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "randomWord",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "enum RandomNumberGenerator.RandomType",
        name: "randomType",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint32",
        name: "totalEntries",
        type: "uint32",
      },
      {
        indexed: false,
        internalType: "uint32",
        name: "selections",
        type: "uint32",
      },
      {
        indexed: false,
        internalType: "string",
        name: "title",
        type: "string",
      },
    ],
    name: "ReturnedRandomWord",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint16[]",
        name: "results",
        type: "uint16[]",
      },
    ],
    name: "ReturnedResults",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "randomWord",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "requestId",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "address",
            name: "partnerContract",
            type: "address",
          },
          {
            internalType: "uint32",
            name: "totalEntries",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "totalSelections",
            type: "uint32",
          },
          {
            internalType: "string",
            name: "title",
            type: "string",
          },
        ],
        indexed: false,
        internalType: "struct RandomNumberGenerator.Params",
        name: "params",
        type: "tuple",
      },
    ],
    name: "ReturnedRandomWord",
    type: "event",
  },
]);

export { ERC721_ABI, RANDOM_ABI };
