import { Container } from "@mui/material";
import NetWarning from "compoments/NetWarning";
import logoOC from "images/oc-logo-middle-orange-dark.svg";

const Header = () => {
  return (
    <header className="app-header-wrapper">
      <Container className="app-header">
        <a href="https://orangecomet.com/" target="_blank" rel="noreferrer">
          <img className="oc-logo" src={logoOC} alt="Orange Comet - Logo - A Web3 Enertainment and Tech Company" />
        </a>
        <NetWarning />
      </Container>
    </header>
  );
};

export default Header;
