/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { AlchemyProvider, TransactionReceipt, Block } from "@ethersproject/providers";
import BlockInfo from "../compoments/Validator/BlockInfo";
import ReceiptInfo from "../compoments/Validator/ReceiptInfo";
import Loader from "compoments/Loader/Loader";

// MUI
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Button, Chip, Container, Grid, Link, TextField } from "@mui/material";

// Template Parts
import Header from "layout/Header";
import Footer from "layout/Footer";
import ShareLinkButton from "compoments/ShareLinkButton";

const theme = createTheme({});

const ValidatorForm = () => {
  // URL Params or default values
  const defaultTxhash = process.env.REACT_APP_DEFAULT_TX;
  const { txhash } = useParams();
  let [searchParams] = useSearchParams();
  const txHashSearchQuery = searchParams.get("txHash");
  const ignoreIndexes = searchParams.get("ignore");

  // Input states
  const [transactionHash, setTransactionHash] = useState<string>(txhash || txHashSearchQuery || defaultTxhash || "");
  const [ignoreString, setIgnoreString] = useState<string>(ignoreIndexes || "");
  const [ignoreNumbersList, setIgnoreNumbersList] = useState<number[]>([]);

  // Loading states
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Results states
  const [receipt, setReceipt] = useState<TransactionReceipt | undefined>(undefined);
  const [block, setBlock] = useState<Block | undefined>(undefined);

  // Input validation states
  const [transactionHastValidationError, setTransactionHashValidationError] = useState<boolean>(false);
  const [ignoreStringValidationError, setIgnoreStringValidationError] = useState<boolean>(false);

  useEffect(() => {
    if (txHashSearchQuery || txhash) {
      setIsLoading(true);
      const isInputValid = validateInputs();

      if (!isInputValid) {
        setIsLoading(false);
      } else {
        getTransaction(transactionHash);
      }
    }
  }, []);

  //   useEffect(() => {
  //     if ((txHashSearchQuery && transactionHash) || (ignoreIndexes && ignoreString)) {
  //       setSearchParams("");
  //     }
  //   }, [txHashSearchQuery, ignoreIndexes]);

  useEffect(() => {
    if (receipt && isLoading) setIsLoading(false);
  }, [receipt, isLoading]);

  // Functions
  const getTransaction = async (hash: string) => {
    const provider = new AlchemyProvider(process.env.REACT_APP_CHAIN, process.env.REACT_APP_ALCHEMY_KEY);
    const receiptResult: TransactionReceipt = await provider.getTransactionReceipt(hash);

    if (!receiptResult?.type) {
      setTransactionHashValidationError(true);
      setIsLoading(false);
      return;
    }

    const blockResult = await provider.getBlock(receiptResult.blockNumber || 0);

    setReceipt(receiptResult as TransactionReceipt);
    setBlock(blockResult as Block);

    if (isLoading) setIsLoading(false);
  };

  const handleTransactionHashInputChange = (value: string) => {
    if (transactionHash) {
      setReceipt(undefined);
      setBlock(undefined);
    }

    if (transactionHastValidationError) setTransactionHashValidationError(false);

    setTransactionHash(value);
  };

  const handleIgnoreStringInputChange = (value: string) => {
    if (ignoreString) {
      setReceipt(undefined);
      setBlock(undefined);
    }

    if (ignoreStringValidationError) setIgnoreStringValidationError(false);

    setIgnoreString(value);
  };

  const validateInputs = () => {
    if (
      transactionHash.length !== 66 ||
      !transactionHash.startsWith("0x") ||
      !/^0x([A-Fa-f0-9]{64})$/.test(transactionHash)
    ) {
      setTransactionHashValidationError(true);
      return false;
    }

    if (ignoreString) {
      const ignoreList = ignoreString.split(",");
      if (ignoreList.some((item: any) => isNaN(item))) {
        setIgnoreStringValidationError(true);
        return false;
      }
    }
    return true;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const validInputs = validateInputs();
    if (!validInputs) return;
    setIsLoading(true);

    if (ignoreString) {
      const ignoreList = ignoreString.split(",").map((item) => parseInt(item));
      setIgnoreNumbersList(ignoreList);
    }

    getTransaction(transactionHash);
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Header />

        <Container component="main" className="app-contet-wrapper align-full">
          <Container component="section" className="oc--form-wrapper">
            <header className="block-cover hero-architect light">
              <div className="block-cover-container">
                <h1>Randomness Verification</h1>
              </div>
            </header>

            <Container className="oc--form random" maxWidth="lg">
              <div className="form-content">
                <h2>Transparency Log</h2>
                <p>
                  This app checks the transactions logs of a specified Chainlink: VRF Coordinator V2 Random Words
                  fulfillment and uses the random word that was generated as the seed to select random numbers from
                  within a number range.
                </p>
                <span className="validateDOMNesting">
                  To get started, enter a transaction hash created by the{" "}
                  <Chip label="fulfillRandomWords" variant="outlined"></Chip>
                  function of a valid Random Number Generator contract developed by Orange Comet.
                </span>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: "100%" }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="txHash"
                    label="Transaction Hash"
                    name="txHash"
                    autoComplete="txHash"
                    autoFocus
                    value={transactionHash}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleTransactionHashInputChange(e.target.value)
                    }
                    error={transactionHastValidationError}
                    helperText={transactionHastValidationError ? "Please enter a valid transaction hash" : ""}
                  />
                  <p>
                    If there are numbers within the sequence that you want to exclude from being selected, enter them as
                    a comma delimited list
                  </p>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="ignoreIndexes"
                    label="Ignore Indexes"
                    name="ignoreIndexes"
                    // helperText="If there are numbers within the sequence that you want to exclude from being selected, enter them as a comma delimited list"
                    value={ignoreString}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleIgnoreStringInputChange(e.target.value)}
                    error={ignoreStringValidationError}
                    helperText={ignoreStringValidationError ? "Please enter a valid list of numbers" : ""}
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={typeof receipt === "object"}>
                    Get Results
                  </Button>
                </Box>
              </div>
              <Box className="oc--form-results">
                <BlockInfo block={block} />
              </Box>
              <Box className="oc--form-results">
                <ReceiptInfo receipt={receipt} ignoreList={ignoreNumbersList} />
              </Box>
              {receipt && (
                <ShareLinkButton
                  link={
                    ignoreString
                      ? `${window.location.hostname}/${transactionHash}&ignore=${ignoreString}`
                      : `${window.location.hostname}/${transactionHash}`
                  }
                />
              )}
            </Container>

            <Container component="footer" maxWidth="lg">
              <Grid container>
                <Grid item xs>
                  <Link href="https://docs.chain.link/docs/vrf/v2/introduction/" variant="body2">
                    Chainlink VRF Documentation
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="https://github.com/OrangeComet/anthony-hopkins-the-eternal-metadata" variant="body2">
                    {"Project on Github"}
                  </Link>
                </Grid>
              </Grid>
            </Container>
          </Container>
        </Container>

        <Footer />

        <Loader open={isLoading} />
      </ThemeProvider>
    </React.Fragment>
  );
};

export default ValidatorForm;
