import React, { useState } from "react";
import { Alert, AlertTitle, Avatar, List, ListItem, ListItemText, ListItemAvatar } from "@mui/material";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { Icon as Iconify } from "@iconify/react";
import { ethers } from "ethers";
import { Log, TransactionReceipt } from "@ethersproject/abstract-provider";
import { AlchemyProvider } from "@ethersproject/providers";
import { shuffle } from "utils/tools";
import { ERC721_ABI, RANDOM_ABI } from "utils/abi";

export interface ReceiptInfoProps {
  receipt: TransactionReceipt | undefined;
  ignoreList: number[] | [];
}

const ReceiptInfo = ({ receipt, ignoreList }: ReceiptInfoProps) => {
  const [contractName, setContractName] = useState<string>("");
  const [contractSymbol, setContractSymbol] = useState<string>("");

  const setContractAttributes = async (partnerContract: string) => {
    const provider = new AlchemyProvider(process.env.REACT_APP_CHAIN, process.env.REACT_APP_ALCHEMY_KEY);
    const contract = new ethers.Contract(partnerContract, ERC721_ABI, provider);

    // Get Name/Symbol from partner Contract
    await contract.symbol().then((result: string) => setContractSymbol(result));
    await contract.name().then((result: string) => setContractName(result));
  };

  const renderRandomWord = (args: ethers.utils.Result) => {
    const { params, randomWord } = args;
    const results = shuffle(randomWord, params.totalEntries)
      .filter((el) => !ignoreList.map((x) => Number(x)).includes(el))
      .slice(0, params.totalSelections);

    setContractAttributes(params.partnerContract);

    return (
      <>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <Iconify icon="mdi:ethereum"></Iconify>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`Partner Contract Address - ${params.partnerContract}`}
            secondary={`Name: ${contractName}, Symbol: ${contractSymbol}`}
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <ReceiptIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`Random Results - ${params.title}`}
            secondary={`Selections: ${params.totalSelections}, Total Entries: ${params.totalEntries}`}
          />
        </ListItem>
        <ListItem>
          <Alert severity="success">
            <AlertTitle>Chosen Results</AlertTitle>
            {JSON.stringify(results, null, 2)}
          </Alert>
        </ListItem>
      </>
    );
  };

  const renderLog = (log: Log) => {
    const validEventNames = ["ReturnedRandomWord"];

    try {
      const parsedLog = RANDOM_ABI.parseLog(log);

      if (validEventNames.indexOf(parsedLog.name) < 0) {
        return null;
      }

      return (
        <React.Fragment key={parsedLog.name}>
          {parsedLog.args.randomWord && renderRandomWord(parsedLog.args)}
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <ReceiptIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={parsedLog.name} />
          </ListItem>
          <ListItem>
            <Alert severity="success">{JSON.stringify(parsedLog.args[0], null, 2)}</Alert>
          </ListItem>
        </React.Fragment>
      );
    } catch (error) {
      return null;
    }
  };

  if (receipt) {
    return <List sx={{ width: "100%", bgcolor: "background.paper" }}>{receipt.logs.map((log) => renderLog(log))}</List>;
  }

  return null;
};

export default ReceiptInfo;
