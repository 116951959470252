import { Container } from "@mui/material";
import logoOC from "images/oc-logo-middle-orange-dark.svg";
import logoNori from "images/nori-logo-2022.webp";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";

const Footer = () => {
  return (
    <footer className="app-footer-wrapper">
      <Container className="app-footer" maxWidth="xl">
        <div className="col oc-branding">
          <a href="https://orangecomet.com/" target="_blank" rel="noreferrer">
            <img className="oc-logo" src={logoOC} alt="Orange Comet - Logo - A Web3 Enertainment and Tech Company" />
          </a>
          <p>
            <strong>Orange Comet, Inc.</strong>
            <br />
            1880 Century Park East, Suite 1600
            <br />
            Los Angeles, CA 90067
          </p>
        </div>

        <div className="col oc-info">
          <a href="https://nori.com/" target="_blank" rel="noreferrer">
            <img src={logoNori} alt="Nori Carbon - Carbon Removal Marketplace" />
          </a>
          <p>Orange Comet is deeply committed to running on green servers and building a carbon-negative solution 🌿</p>
        </div>
      </Container>

      <div className="bottom-bar-wrapper">
        <Container className="bottom-bar" maxWidth="xl">
          <p className="copyright">©2022 Orange Comet, Inc. All Rights Reserved.</p>
          <ul className="social">
            <li id="twitter">
              <a target="_blank" href="https://twitter.com/orangecometnft" rel="noreferrer">
                <TwitterIcon />
                <span className="screen-reader-text">twitter</span>
              </a>
            </li>
            <li id="instagram">
              <a target="_blank" href="https://www.instagram.com/orangecometnft" rel="noreferrer">
                <InstagramIcon />
                <span className="screen-reader-text">instagram</span>
              </a>
            </li>
          </ul>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
